import MaskedInput from "react-text-mask";

export const InputWithMask = (props) => {
  const { mask, placeholder, type="text", style, ...otherProps } = props;

  return (
    <MaskedInput
      className={style.FormInput}
      type={type}
      placeholder={placeholder}
      guide={false}
      mask={mask}
      {...otherProps}
    />
  );
};

import InputMask from "react-input-mask";
import {InputContainer} from "../UI/InputContainer/InputContainer";

export const InputCardNumber = (props) => {
  const { inputData, formFields, onChange, style, ...otherProps } = props;

  const name = inputData.fieldId;
  const value = formFields[name.toString()];

  const mask = inputData.inputMask?.replace(/[0-9]/g, "9").replace(/-/g, " ");

  return (
    <InputContainer>
      <label htmlFor={name}>{inputData.label_ru}</label>
      <InputMask
        className={style.FormInput}
        id={name}
        name={name}
        placeholder={inputData.label_ru || "Номер карты"}
        value={value}
        onChange={onChange}
        mask={mask}
        maskChar={null}
        {...otherProps}
      />
    </InputContainer>
  );
};
export const cardType = [
  {
    id: 1,
    label: "Оплатить картой КР"
  }
];

export const cardType2 = [
  {
    id: 1,
    label: "Оплатить картой КР"
  },
  {
    id: 2,
    label: "Оплатить картой РФ"
  }
];
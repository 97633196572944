import {apiAxiosInstance} from "./apiInstance";

export const subscribe = async (setResultData, uuid) => {
  try {
    const {data} = await apiAxiosInstance.get(`/api/orders/${uuid}`);

    setResultData({
      title: data.state.title,
      style: data.state.style,
      order: data.user_ref,
      amount: data.amount
    })

    if (data.state.final === true) {
      return;
    }

  } catch (e) {
    console.log("error", e);
  }

  setTimeout(() => {
    subscribe();
  }, 3000);
};

import {apiAxiosInstance} from "./apiInstance";

export const sendForm = async (fullFormData) => {
  try {
    const response = await apiAxiosInstance.post("/api/orders", fullFormData);
    const { data } = response;

    if (data.finish_url) {
      window.location.replace(data.finish_url)
    } else if (data.form) {
      const form2wrap = document.createElement("div");
      form2wrap.innerHTML = data.form;
      document.body.appendChild(form2wrap);
      form2wrap.getElementsByTagName("form")[0].submit();
    }

  } catch (error) {
    console.error(error);
  }
}

import React, {useContext, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {cardType} from "../../utils";
import {
  Button,
  FormInput,
  InputCardNumber,
  InputHidden,
  InputMoney,
  InputPhone,
  SelectCompt,
  InputContainer
} from "../index";
import cls from "./Form1.module.scss";

export const Form1 = (props) => {
  const {item, defaultFormFields, onChangeStage, formContextProp, inputStyle} = props;
  const {setFormData} = useContext(formContextProp);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [selectCard, setSelectCard] = useState(cardType[0].id);
  const [mail, setMail] = useState("");

  const onChangeForm = (e) => {
    const {name, value} = e.target;
    setFormFields({...formFields, [name]: value});
  };

  const InputsArray = item.data.requiredFields.map((inputData) => {
    switch (inputData.keyboardType) {
      case "NUMBER":
        return <InputCardNumber key={inputData.fieldId} formFields={formFields} onChange={onChangeForm}
                                inputData={inputData} style={inputStyle}/>;
      case "MONEY":
        return <InputMoney key={inputData.fieldId} formFields={formFields} onChange={onChangeForm}
                           inputData={inputData}
                           itemData={item.data} style={inputStyle}/>;
      case "TEXT":
        return <InputHidden key={inputData.fieldId} formFields={formFields} setFormFields={setFormFields}
                            inputData={inputData}/>;
      case "PHONE":
        return <InputPhone key={inputData.fieldId} formFields={formFields} onChange={onChangeForm}
                           inputData={inputData} style={inputStyle}/>;
      default:
        return "";
    }
  });

  const onSubmitForm = (e) => {
    e.preventDefault();

    const commission = Math.round(formFields.total * 0.024);

    const newFormData = {
      service_id: item.data.id,
      card_type: selectCard,
      mail,
      commission,
      amount: (Number(formFields.total) + commission).toString(),
      user_ref: uuidv4(),
      fields: {...formFields}
    };
    setFormData(newFormData);

    onChangeStage(); // Переключение на следующий этап
  };

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, []);

  return (
    <form onSubmit={onSubmitForm}>
      <div className={cls.inputsWrap}>
        <SelectCompt item={item} selectCard={selectCard} setSelectCard={setSelectCard} style={inputStyle}/>

        {InputsArray}

        <InputContainer>
          <label htmlFor={"mail"}>Электронный адрес для чека</label>
          <FormInput
            style={inputStyle}
            name="mail"
            placeholder="name@mail.com"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </InputContainer>

        <div className={cls.description}>
          <p>Валюта: RUB</p>
          <p>Минимальная сумма: 1200 ₽</p>
          <p>Максимальная сумма: {item.data.maxAmount} ₽</p>
          <p>Комиссия: 2,4%</p>
        </div>
        <Button type="submit">
          Далее
        </Button>
      </div>
    </form>
  );
};

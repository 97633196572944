import {InputContainer} from "../UI/InputContainer/InputContainer";

export const InputMoney = (props) => {
  const { inputData, formFields, onChange, itemData, style, ...otherProps } = props;

  const name = inputData.fieldId;
  const value = formFields[name.toString()];

  const max = itemData.maxAmount || 9999999;
  const min = 1200;

  return (
    <InputContainer>
      <label htmlFor={name}>{inputData.label_ru}</label>
      <input
        className={style.FormInput}
        type="number"
        max={max}
        min={min}
        id={name}
        name={name}
        placeholder={inputData.label_ru || "Сумма"}
        value={value}
        onChange={onChange}
        {...otherProps}
      />
    </InputContainer>
  );
};
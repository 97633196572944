import {cardType, cardType2} from "../../utils";
import {InputContainer} from "../UI/InputContainer/InputContainer";
//import cls from "./SelectCompt.module.scss";

export const SelectCompt = (props) => {
  const {setSelectCard, selectCard, item, style} = props;

  const changeSelect = (e) => {
    setSelectCard(e.target.value);
  };

  const options = item.external_id === "kg2rf"
    ? cardType
    : cardType2;

  return (
    <InputContainer>
      <label htmlFor="cardSelect">
        Выберите тип карты, с которой нужно снять денежные средства.
      </label>
      <select
        id="cardSelect"
        value={selectCard}
        onChange={changeSelect}
        className={style.FormInput}
      >
        {
          options.map((item) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))
        }
      </select>
    </InputContainer>
  );
};
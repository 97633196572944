import cls from "./StatsBlock.module.scss";
import statIcon1 from '../../../assets/image/statIcon1.svg'
import statIcon2 from '../../../assets/image/statIcon2.svg'
import statIcon3 from '../../../assets/image/statIcon3.svg'

const StatsBlock = () => {
  return (
    <div className={cls.StatsBlock}>
      <div className={cls.container}>
        <div className={cls.item}>
          <img src={statIcon1} alt="statIcon" />
          <p>Оплачивайте мобильную связь</p>
        </div>
        <div className={cls.item}>
          <img src={statIcon2} alt="statIcon" />
          <p>Совершайте коммунальные платежи</p>
        </div>
        <div className={cls.item}>
          <img src={statIcon3} alt="statIcon" />
          <p>Переводите средства на карты РФ</p>
        </div>
      </div>
    </div>
  );
};

export default StatsBlock;

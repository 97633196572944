import { useContext, useEffect } from "react";
import {FormTitle,Button} from "../index";
import cls from "./Form1.module.scss";

export const Form2 = (props) => {
  const { onChangeStage, formContextProp } = props;
  const { formData } = useContext(formContextProp);

  const onSubmitForm = (e) => {
    e.preventDefault();

    onChangeStage();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <form onSubmit={onSubmitForm}>
      <FormTitle>Подтверждение</FormTitle>
      <div className={cls.inputsWrap}>
        <div className={cls.dataForm}>
          <h4>Номер</h4>
          <p>{formData.fields["account.value.persacc"]}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Сумма платежа</h4>
          <p>{formData.fields.total} ₽</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Комиссия</h4>
          <p>{formData.commission} ₽</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Электронный адрес для чека</h4>
          <p>{formData.mail}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Магазин</h4>
          <p>skypay.kg</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Заказ</h4>
          <p>{formData.user_ref}</p>
        </div>
        <Button type="submit">К оплате</Button>
      </div>
    </form>
  );
};

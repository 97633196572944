import cls from "./Footer.module.scss";
import logoSkypay from "../../assets/image/skypayLogoBlack.svg";
import paySystems from "../../assets/image/paySystems2.png";
import doc203 from "../../assets/pdf/203.pdf";
import doc303 from "../../assets/pdf/303.pdf";
import oferta from "../../assets/pdf/oferta.pdf";
import policy from "../../assets/pdf/policy.pdf";

const Footer = () => {
  return (
    <div className={cls.Footer}>
      <div className={cls.container}>
        <div className={cls.topFooter}>
          <div className={cls.logo}>
            <img src={logoSkypay} alt="logo" />
            <p>Всегда под рукой для онлайн оплаты</p>
          </div>
          <div className={cls.content}>
            <div className={cls.col}>
              <h4>Компания</h4>
              <a href="#about-us">
                О Skypay
              </a>
              <a href="#catalog">
                Каталог услуг
              </a>
              <a href="#questions">
                Актуальные вопросы
              </a>
              <a href={oferta} target="_blank" rel="noreferrer">
                Договор о предоставлении услуг
              </a>
              <a href={policy} target="_blank" rel="noreferrer">
                Политика обработки cookies
              </a>
            </div>
            <div className={cls.col}>
              <h4>Связаться с нами</h4>
              <div>+996 552 500 520</div>
              <div>info@skypay.kg</div>
            </div>
            <div className={cls.col}>
              <h4>Юридический адрес</h4>
              <p>Кыргызская Республика, г. Бишкек, ул. Юнусалиева, д. 80, офис 429</p>
              <p>Руководитель: <br /> Генеральный директор Оморкулов Сатвалды Сапаралиевич</p>
            </div>
            <div className={cls.col}>
              <h4>Реквизиты</h4>
              <p>ИНН 02904202310075</p>
              <p>ОКПО 31945786</p>
              <p>001 УГНС по Октябрьскому району</p>
              <p>Банк: Центральный филиал ОАО “Дос-Кредобанк”</p>
              <p>БИК 121015</p>
              <p>р/с 1212000200394142</p>
            </div>
          </div>
        </div>
        <div className={cls.line}></div>
        <div className={cls.policy}>
          <p>Copyright ©2023 Общество с ограниченной ответственностью «Скай Пэй». <br /> Лицензии компании
            <a target="_blank" rel="noreferrer" href={doc203}> №2035270723 от 27.07.2023 г.</a>,
            <a target="_blank" rel="noreferrer" href={doc303}> №3037270723 от 27.07.2023 г.</a>
          </p>
          <img src={paySystems} alt="systems" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
